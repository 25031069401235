/*
 * 업무구분: 안내자료
 * 화 면 명: MSPSA207M
 * 화면설명: 안내자료 리스트
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.14
 * 작 성 자: 1차-손평국 / 2차-김영수 @click="$router.go(-1)"    @on-header-left-click="fn_HistoryBack" 
 */
<template>
  <ur-page-container class="msp" type="subpage" :title="lv_DeptNm1" :show-title="false" @on-scroll-bottom="fn_SearchMoreData()" ref="page" ><!--:action-type="actionSlot"-->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap">
      <!-- 상단고정 Tab UI -->
      <div class="ns-sliding-topbar">
        <mo-collapsing-header-view headerColor="#fff" :fixedDiff="34" ref="headerview">
          
          <template #fixed="{scrollRate}">
            <mo-top-bar color="primary"   :scroll-target="lv_HeaderviewScrollCompID" scroll-shadow>
              <mo-button class="btn_popup_back" @click="fn_HistoryBack"></mo-button>
              <div slot="nav" class="icon-wrapper" @click="fn_HistoryBack" >
                  <mo-icon color="white" icon-size="36px" borderless  >drawer</mo-icon>
              </div>
              <div slot="action" >
                <span><mo-button style="margin-right: 13px" shape="border" class="ns-btn-round btn-title-style-1" @click="clickCategory()">분류별보기</mo-button></span>
                <!-- <mo-icon borderless>category</mo-icon>icon-size="36px" -->
              </div>
              <!-- 헤더 타이틀 영역 -->    
              {{scrollRate > 0.5 ? lv_DeptNm1 : lv_DeptNm1}}
              <!-- 헤더 타이틀 영역 //-->
            </mo-top-bar>
          </template>

          <!-- collapsing 영역 -->
          <template #collapsing>
            <div style="height: 1px"></div>
          </template>

          <template #sticky>
            <!-- 메인 카테고리 출력 -->
            <mo-tab-box :default-idx="dept1Idx" primary class="ns-move-tab-box" ref="tabBox">
              <!-- tab id loop -->
              <template v-for="item in lv_DeptLst2"> 
                <mo-tab-label :idx="item.idx" :key="item.clsfSytmId" @click="fn_Dept2Click(item)">{{item.clsfSytmNm}}</mo-tab-label>
              </template>
              <!-- tab id loop //-->
            </mo-tab-box>
            <!-- 메인 카테고리 출력 //-->
            <!-- 서브카테고리 출력 -->
            <ur-box-container alignV="start" componentid="ur_box_container_111" direction="column">
              <ur-tabs-container componentid="ur_tabs_container_110" :tabId=tabIdValue class="ns-tabs-box2 no-tab" ref="tabsContainer" @tab-changed="fn_Dept3Click">
                <template v-for="item in lv_DeptLst3"> 
                  <ur-tab-item-container  :componentid="item.aezet" :title="item.smoUnitl" :tabId="item.aezet" :key="item.aezet"></ur-tab-item-container>
                </template>
              </ur-tabs-container>         
            </ur-box-container>
            <!-- 서브카테고리 출력 //-->

             <ur-box-container alignV="start" componentid="" direction="column"  class="ns-check-sum bd-b-Ty1">
                 <mo-list-item>
                    <div  class="list-item__contents">            
                       <div class="list-item__contents__title">
                         <span class="sum">{{ lv_SelectedItemList.length > 0 ? lv_SelectedItemList.length + '건 선택' : '총 ' + lv_ClsfSymtCntntBasInfoVOList.length + '건' }}</span> 
                         <mo-button class="btn_option_list" @click="fn_listShow()" v-if="lv_show"></mo-button>
                         <mo-button class="btn_option_grid" @click="fn_listShow()" v-else></mo-button>                          
                       </div>
                    </div>   
                                
                 </mo-list-item>
             </ur-box-container>            
          </template>

         <template #scroll>
          <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp msp-sa-wrap" :class="lv_Class ? 'pb100' : ''"> <!-- 퍼블가이드 : 하단 툴바 오픈시 pb100 추가삭제요청 --> 
          <!-- <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="msp msp-sa-wrap" :class="lv_Class"> 퍼블가이드 : 하단 툴바 오픈시 pb100 추가삭제요청  -->
            <!-- 그리드 출력 horizontal  column ,scroll-container  ns-scroll-x-list mb50 -->
            <div v-if="lv_ClsfSymtCntntBasInfoVOList.length > 0 && lv_show" class="w100"> <!-- 퍼블추가 : w100 --> 
              <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-scroll-x-list mb50 pt20"> <!-- 퍼블추가 : pt20 -->          
                <mo-list :list-data="lv_ClsfSymtCntntBasInfoVOList" ref="refList" class="grid_ui--2" >
                  <template #list-item= "{item}">
                    <mo-card class="ns-data-item">
                      <mo-checkbox v-model="lv_SelectedItemList" :value="item" @input="fn_CheckClick(item)" class="z-index1" />
                        <div class="mo-card__contents ">
                           <div class="mo-card__header">
                              <mo-badge class="card__badge gray" color="primary" :text="item.untySlmtClsfNm01" shape="identifier"></mo-badge>
                              <mo-badge class="card__badge orange" color="primary" text="HOT" shape="identifier" v-if="false"></mo-badge>
                              <div class="mo-card__name"  @click="fn_OnSelectedSingleItem(item)">
                                   {{item.untySlmtNm}} 
                              </div>
                           </div>
                           <div class="mo-card__body">
                             <img :src="item.blobImgUrl"/> 
                           </div>
                        </div>
                     <!-- </mo-checkbox>  -->
                    </mo-card>
                  </template>
                </mo-list>
              </ur-box-container>
              <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
              <div class="ns-height100"></div>
            </div>

             <!-- 리스트 출력 -->
            <div v-else  class="w100"> <!-- 퍼블추가 : w100 --> 
              <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list">       
                <mo-list :list-data="lv_ClsfSymtCntntBasInfoVOList" ref="refList">
                  <template #list-item= "{item}">
                    <mo-list-item class="bd-b-Ty1"> <!-- 퍼블추가 : bd-b-Ty1 --> 
                      <mo-checkbox v-model="lv_SelectedItemList" :value="item" @input="fn_CheckClick(item)"/>
                      <div class="list-item__contents">
                        <div class="list-item__contents__title" @click="fn_OnSelectedSingleItem(item)">
                          <span class="name txtSkip">{{item.untySlmtNm}}</span>
                        </div>
                        <div class="list-item__contents__info">
                          <span>{{$commonUtil.dateDashFormat(item.untySlmtRegDtm)}}</span><em>|</em><span>조회 {{item.inqrFgr}}</span>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
              <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
             <!-- <div class="ns-height100"></div> -->
            </div>
            <!-- 리스트 출력 //-->
            <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="no-data" v-if="lv_ClsfSymtCntntBasInfoVOList.length === 0 && isAfterFirstSearch === true">
              <mo-list-item>
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <span>데이터가 존재하지 않습니다.</span>
                  </div>
                </div>
              </mo-list-item>
            </ur-box-container>

           <!-- 토스트 -->
           <mo-snackbar ref="stackableSnackbar" stackable :duration="3000" /> 

          <!-- 동영상 링크 확인 -->
          <mo-bottom-sheet ref="video_bottomsheet" class="ns-bottom-sheet msp-bottomSt-alert">
            <div class="customer-info-txt txt-center">
              <p class="ment">동영상 실행시 데이터 요금이 발생될수 있으니 유의 바랍니다.</p>
            </div>

            <template v-slot:action>
             <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
                <div class="relative-div">
                  <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_video_bottomsheet_close()" name="취소">취소</mo-button>
                  <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_IncrementItemInqrFgrList('Y')" name="확인">확인</mo-button>
                </div>
              </ur-box-container>
            </template>
          </mo-bottom-sheet>           

              <!-- 상세보기 팝업ns-bottom-sheet closebtn title_none  -->
           <mo-bottom-sheet ref="bs10"  class="ns-bottom-sheet ns-style3 msp-bottomSt-alert  only-btn">
               <template v-slot:action>
                 <div class="action-area">
                  <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
                    <mo-checkbox :checked="isSelectedItemList" @input="fn_CheckALLRelease()">{{ isSelectedItemList ? lv_SelectedItemList.length : 0 }} {{'건 선택'}}</mo-checkbox>
                  </ur-box-container>
                  <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
                    <div class="relative-div">
                      <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white ls--2" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent" @click="fn_OpenMSPSA222P()">화상상담 자료저장</mo-button>
                     <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="lv_IsSelectedSmsContent " @click="fn_PDFDetailBtnClicked">상세보기</mo-button>
                    </div>
                  </ur-box-container>
                </div>
                 <ur-box-container componentid="" direction="row">
                   <div class="btn_icon_box--jjh">
                       <mo-button componentid="" class="ns-btn-round message" :disabled="lv_IsSelectedLibraryContent" @click="fn_SMSBtnClicked()" >문자</mo-button>
                       <mo-button componentid="" class="ns-btn-round email_link2" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent" @click="fn_OpenPopEmailCustList()">이메일</mo-button>
                       <mo-button componentid="" class="ns-btn-round print2" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent" @click="fn_print_BottomSheet_open()">인쇄</mo-button>
                       <mo-button componentid="" class="ns-btn-round scrap" :disabled="lv_IsSelectedLibraryContent ||  lv_IsSelectedSmsContent" @click="fn_OpenMSPSA210P()" >스크랩</mo-button>
                   </div>
                 </ur-box-container>
               </template>
            </mo-bottom-sheet> 
           <!-- 상세보기 팝업 //-->

           <mo-bottom-sheet ref="print_bottomSheet"  class="ns-bottom-sheet closebtn">
               <template v-slot:title>
                인쇄
                  <div class="ns-btn-close" @click="fn_print_BottomSheet_close()" name="닫기"></div>
               </template>
       
               <ur-box-container direction="column" alignV="start">        
                  <ur-box-container componentid="namecard">          
                    <ur-box-container componentid="" direction="row" class="fexTy5 pl24 mb30">
                      <span class="mr35">매수</span>
                          <mo-stepper v-model="lv_SpinValue" :value=1  :min="1" :max="20"/>
                    </ur-box-container>
                  </ur-box-container>
               </ur-box-container>

             <template v-slot:action>
              <ur-box-container align-v="start" componentid="" direction="row" align-h="end" ref="_modal__action" class="ns-btn-relative-bs pt0">
                <div class="relative-div">
                  <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_print_BottomSheet_close()">취소</mo-button>
                     <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OzConfirmBtnClicked()">인쇄</mo-button>
                </div>
              </ur-box-container>
            </template>
           </mo-bottom-sheet>


          </ur-box-container>
         </template>
        </mo-collapsing-header-view>    

      </div>
      <!-- 상단고정 Tab UI //-->
    </ur-box-container>
  </ur-page-container>
</template> 
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import DateUtil from '@/ui/sp/common/exDateUtils'
  import SpConstants from '@/config/constants/spConstants'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import Msg from '@/systems/webkit/msg/msg'
  import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
  import MSPSA206P from '@/ui/sa/MSPSA206P.vue'
  import MSPSA210P from '@/ui/sa/MSPSA210P.vue'
  import MSPSA222P from '@/ui/sa/MSPSA222P.vue'
  import MSPSA063P from '@/ui/sa/MSPSA063P.vue'
  import MSPCM128P from '@/ui/cm/MSPCM128P.vue'
  import MSPCM152P from '@/ui/cm/MSPCM152P.vue'
  import MSPCM154P from '@/ui/cm/MSPCM154P.vue'
  import MSPCM191P from '@/ui/cm/MSPCM191P.vue'
  import MSPCM192P from '@/ui/cm/MSPCM192P.vue'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA207M",
    screenId: "MSPSA207M",
    props: {
      pPage: '',
      pIsShowMSPparm222P: Boolean,
      pTargetType: '',
      pSelectedItemList: [],   // 고객목록 리스트
      pSearchCustNm: ''
    },
    components:{
      MSPSA206P,
      MSPSA210P,
      MSPSA222P,
      MSPSA063P,
      MSPCM128P,
      MSPCM152P,
      MSPCM154P,
      MSPCM191P,
      MSPCM192P
    },

    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isAfterFirstSearch: false,
        lv_HeaderviewScrollCompID: '',
        // 지점장 여부
        isMngr: false,
        // 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
        authCd: 'U000',
        // 화면 로드완료 여부
        isCreateComplete: false,
        actionSlot:{},
        // 전달받은 카테고리 리스트(대분류, 중분류, 소분류)
        lv_SelectedCateObject: {},
        // 대분류 Object
        lv_Dept1: {},
        // 중분류 Object
        lv_Dept2: {},
        // 소분류 Object
        lv_Dept3: {},
        // 대분류 명
        lv_DeptNm1: '',
        // 중분류 명
        lv_DeptNm2: '',
        // 소분류 명
        lv_DeptNm3: '',
        // 중분류 리스트
        lv_DeptLst2: [],
        // 소분류 리스트
        lv_DeptLst3: [],
        // 중분류 선택 Index
        dept1Idx: '1',
        // 체크박스
        chkList: [],
        stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
        pageRowCnt: 20,
        stndKeyVal: 'start',
        // 선택된 자료 목록 저장
        lv_SelectedItemList: [],
        // 통합영업자료사용권한코드,기본값은 FC
        lv_UntySlmtUseAuthCd: '1%',
        // 자료 목록
        lv_ClsfSymtCntntBasInfoVOList: [],
        // 자료 전체 목록
        lv_ClsfSymtCntntItems: [],
        // nextKey방식의 페이징 변수
        lv_ClsfSymtCntntStndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}],
        pageRowCnt: 200,
        tabIdValue: '',
        popData: {},
        popData1: {},
        lv_ChkCnt: 0,
        // 고객목록 Object
        custListObj: {},
        // 자료 목록
        lv_CmpxCondCntntItemList: [],
      // 대분류명이 "라이브러리" or "사랑On도움방" 일때 "상세보기" 버튼을 disabled 시킨다.
      lv_IsSelectedLibraryContent: false,
      // 대분류명이 "동영상 일때 "문자전송" 버튼을 disabled 시킨다.
      lv_IsSelectedSmsContent: false,
        // 단건 자료 선택
        lv_SelectedSingleItem: null,
        // 다건 자료 선택,
        lv_IsMadeMultiItem: true,
        // 선택된 카테고리
        lv_SelectedCateObject: {},
        lv_SelectedFomItemList:[],
        lv_SelectedItemListTmp:[],

        lv_show: true,     // 리스트보기
        //인쇄관련 
        lv_IsSelectedMultiItem:  false,  // 다건 자료선택여부  
        lv_IsIncludeCnsltInfo: 'Y', // 컨설턴트 개인정보 이미지 포함 여부
        lv_SpinValue: 1,            // 인쇄매수   
        lv_Popup210: {},  // 나의보관함추가popup      
        lv_Popup222: {},  // 화상상담자료저장poppup
        lv_Popup152: {},  // 수신고객목록
        lv_Popup128: {},  // 안내수신고객목록
        lv_Popup154: {},  // 터치온고객문자발송 
        lv_Popup191: {},  // 이메일수신고객목록
        lv_Popup192: {},  // 터치온고객이메일발송 
        isMSPSA063P: {}, //  화상상담자료조회popup
        lv_TargetType: 'c',
        lv_SelectedCustList: [], // 화상상담자료저장 고객목록 리스트 
        noHeader: false,
        isNofacCnsl: false,  // 비대명 상상 관련 
        lv_Class: false,        //클래스 함수지정
      };
    },


    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    /** computed 함수 정의 영역 */
    computed: {
      isSelectedItemList () {
        return !this.$bizUtil.isEmpty(this.lv_SelectedItemList) && this.lv_SelectedItemList.length > 0 ? true : false
      }
    },
    /** watch 정의 영역 */
    watch: {
    },

    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created () {
      console.log(' 카테고리 조회  >>>> created')
      // backKey event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      this.fn_SetMenu(this.$route.params.lv_SelectedCateObject);
      this.fn_SelClsfSymtCntnt()
    },


    /** 설명 : Step-4 마운트 된 후 호출 */
    mounted () {
      // 스크롤 애니메이션 instance 선언
      this.lv_HeaderviewScrollCompID = this.$refs.headerview.getScrollElementId()
      document.getElementById(this.lv_HeaderviewScrollCompID).addEventListener('scroll', this.$refs.page.onScrollEvent)

      // 화면접속로그 등록(필수)
      this.$bizUtil.insSrnLog('MSPSA207M')
    },
    

    /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
    beforeDestroy () {
      // backKey event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

      this.$BottomManager.fn_SetBottomVisible(true)
    },

    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
      * Function명: fn_Init
      * 설명: 초기화 작업
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_Init () {
        // 사용자 정보
        this.lv_userInfo = this.getStore('userInfo').getters.getUserInfo
        
      },
      open() { 
        this.lv_Class = true
        this.$refs.bs10.open(); 
      },
      close() { 
        this.lv_Class = false
        this.$refs.bs10.close(); 
      },
      clickCategory() {
        this.fn_OpenCate();
      },
      /******************************************************************************
      * Function명: fn_Reset
      * 설명: 리셋
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_Reset () {
        //this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
        this.lv_SelectedItemList = []
        this.lv_ClsfSymtCntntStndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}]
        this.stndKeyList = [{stndKeyId: 'nextKey', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}]
        this.stndKeyVal = 'start'
        this.lv_ClsfSymtCntntBasInfoVOList = []
        this.lv_DeptLst2 = []
      },

      /*********************************************************
      * Function명: fn_CheckALLRelease
      * 설명: 전체체크박스 해제
      *********************************************************/
      fn_CheckALLRelease() {
        this.lv_SelectedItemList = []        
        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
      },
      
      /******************************************************************************
      * Function명: fn_SetMenu();
      * 설명: 메뉴 구성
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_SetMenu (menuObject) {
        let emptyCnt = 0
        let tabId = ''
        let lv_vm = this
        
        this.lv_SelectedItemList = []
        this.lv_ClsfSymtCntntBasInfoVOList = []

        this.fn_Reset()
        this.lv_SelectedCateObject = menuObject

        if(this.lv_SelectedCateObject.item1 === undefined || this.lv_SelectedCateObject.item1 === null){
          emptyCnt ++
        }
        if(this.lv_SelectedCateObject.item2 === undefined || this.lv_SelectedCateObject.item2 === null){
          emptyCnt ++
        }
        if(this.lv_SelectedCateObject.item3 === undefined || this.lv_SelectedCateObject.item3 === null){
          emptyCnt ++
        }

        if(emptyCnt > 0){
          return false;
        }

        // 선택한 대,중,소분류 Object
        this.lv_Dept1 = this.lv_SelectedCateObject.item1
        this.lv_Dept2 = this.lv_SelectedCateObject.item2
        this.lv_Dept3 = this.lv_SelectedCateObject.item3
       
        // 선택한 대,중,소분류명
        this.lv_DeptNm1 = this.lv_Dept1.clsfSytmNm 
        this.lv_DeptNm2 = this.lv_Dept2.clsfSytmNm 
        this.lv_DeptNm3 = this.lv_Dept3.smoUnitl 
        
        // 중,소분류 리스트
        this.lv_DeptLst2.push({clsfSytmId:'home', idx:'1', clsfSytmNm:'홈', tmpId:'00', tTSA00003DVO:[{aezet: 'home', smoUnitl: '홈'}]})
        
        for(let j = 0; j < this.lv_Dept1.sAMClsfSytmCntntVO.length; j++){
          this.lv_DeptLst2.push(this.lv_Dept1.sAMClsfSytmCntntVO[j]);
          
        }
        //this.lv_DeptLst2 = this.lv_Dept1.sAMClsfSytmCntntVO
        this.lv_DeptLst3 = this.lv_Dept2.tTSA00003DVO
        console.log('this.lv_DeptLst2')
        console.log(this.lv_DeptLst2)



        // 중분류 강제 선택
        for(let i = 0; i < this.lv_DeptLst2.length; i++){
          // 인덱스 배정 '1','2','3'
          this.lv_DeptLst2[i].idx = (i + 1).toString()
          // 선택한 중분류코드로 강제 선택
          if(this.lv_DeptLst2[i].clsfSytmId === this.lv_Dept2.clsfSytmId){
            this.dept1Idx = (i + 1).toString()
            break
          }
        }// for
        
        this.tabIdValue = lv_vm.lv_Dept3.aezet
        // 소분류 강제선택은 카테고리 코드로 el에 세팅
        setTimeout(() => {
          
          // UI_DEV 수정되면 타임아웃 삭제
          lv_vm.isCreateComplete = true
          lv_vm.$refs.tabsContainer.toggleTab(lv_vm.lv_Dept3.aezet)
          this.$refs.tabBox.setIdx(this.dept1Idx)
        })
      },


      /*********************************************************
       * Function명: fn_OpenCate
       * 설명: 카테고리 버튼 클릭 팝업오픈
       * Params: N/A
       * Return: N/A
       *********************************************************/
      fn_OpenCate (popup) {
        console.log('카테고리클릭 >>>  fn_OpenCate')
        let lv_vm = this

        // lv_UntySlmtClsf 길이가 0 이면 '카테고리' 화면으로 이동금지
        // if ( lv_vm.lv_UntySlmtClsf.length === 0) {
        //   return false
        // }
        this.popData3 = this.$bottomModal.open(MSPSA206P,{
          properties: {
            //paramData: this.?? // param셋팅
          },
          listeners: {
            // 팝업 닫히면서 실행되는 함수
            popupCloseMSPSA206P: (pData) => {
              if(pData !== null){
                this.lv_SelectedCateObject = pData
                // this.custListObj = [{targetType: 's', parentsViewId: 'TSSSP201M', custList: pData}]
              }
              this.$bottomModal.close(lv_vm.popData3)
              this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
              this.lv_SelectedCateObject = pData
              this.lv_show = true      // 그리드형보기 (카테고리 및  메뉴 선택시 그리드형으로 전환
              this.fn_SetMenu(this.lv_SelectedCateObject);
              this.fn_SelClsfSymtCntnt()
             
            }
          }
        })
      },


      /******************************************************************************
      * Function명: fn_Dept2Click
      * 설명: 중분류 클릭 이벤트
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_Dept2Click(item){

        let menuObject = {}
        let dept1 = {}
        let dept2 = {}
        let dept3 = {}

        this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
        if(item.idx === '1'){
          this.$router.push({
            name: 'MSPSA201M',
            params: {
              callerView: 'MSPSA207M'
            }
          })
          return false
        }

        if(item.tTSA00003DVO[0] === undefined || item.tTSA00003DVO[0] === null || item.tTSA00003DVO[0] === 0){
          return false
        }

        dept1 = this.lv_Dept1
        dept2 = item
        dept3 = item.tTSA00003DVO[0]

        menuObject = { item1:dept1, item2:dept2, item3:dept3 }
        this.lv_show = true      // 그리드형보기 (카테고리 및  메뉴 선택시 그리드형으로 전환
        this.fn_SetMenu(menuObject)
      },
      /******************************************************************************
       * Function명: fn_Dept3Click
       * 설명: 소분류 클릭 이벤트
       * Params: N/A
       * Return: N/A
      ******************************************************************************/
      fn_Dept3Click(tabId){
       if(this.isCreateComplete === false) return;
       this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)

       let menuObject = {}
        let dept1 = {}
        let dept2 = {}
        let dept3 = {}

        dept1 = this.lv_Dept1
        dept2 = this.lv_Dept2

        for(let i = 0; i < this.lv_DeptLst3.length; i++){
          if(this.lv_DeptLst3[i].aezet === tabId){
            dept3 = this.lv_DeptLst3[i]
          }
        }

        menuObject = { item1:dept1, item2:dept2, item3:dept3 }
        this.lv_show = true      // 그리드형보기 (카테고리 및  메뉴 선택시 그리드형으로 전환
        this.fn_SetMenu(menuObject)
        this.fn_SelClsfSymtCntnt()

      },
      
      /******************************************************************************
      * Function명 : fn_SearchMoreData
      * 설명       : 안내자료 더보기
      ******************************************************************************/
      fn_SearchMoreData () {
        // 전체고객목록 조회
        this.fn_SelClsfSymtCntnt()
      },

      /******************************************************************************
      * Function명 : fn_listShow()
      * 설명       : 안내자료 리스트 유형 변경 처리  (  기본-그리드형 / 리스트 형)
      ******************************************************************************/
      fn_listShow() {
        console.log( '@@@ 데이타 조회 유형 확인 .. fn_listShow start' +  this.lv_show) 
        this.close()
        this.lv_show = !this.lv_show
        this.fn_SetMenu(this.lv_SelectedCateObject)
        this.fn_SelClsfSymtCntnt()
      },
      /******************************************************************************
      * Function명: fn_SelClsfSymtCntnt
      * 설명: 안내자료 리스트 조회
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_SelClsfSymtCntnt(){
        console.log( '@@@ 카테고리 조회 fn_SelClsfSymtCntnt start')

        if (this.stndKeyVal === 'end') return

        let lv_vm = this
        let pParams = {}
        let trnstId = ''
        let tmp = ''
        let pClsfSymtId = ''

        lv_vm.isAfterFirstSearch = false
        
        tmp = this.lv_Dept3.envCntnt
        pClsfSymtId = tmp.slice(6)
        
        pParams.untySlmtUseAuthCd = lv_vm.lv_UntySlmtUseAuthCd
        pParams.moblInqrYn = 'Y' // 모바일에서 호출여부

        if(pClsfSymtId === '000R'){
          // 최신자료
          trnstId = 'txTSSSA01S1'
          // 전체 구분값(전체보기:Y, 관심영역:N)
          pParams.whlInqrYn = 'Y'

        }else if (pClsfSymtId === '000P'){
          // 인기자료
          trnstId = 'txTSSSA01S2'
          // 기간 구분값(1개월:1, 3개월:2)
          pParams.inqrScVal = '3'

        }else if (pClsfSymtId === '000M'){
          // 내가사용한자료
          trnstId = 'txTSSSA01S3'
          // 전체 구분값(전체보기:Y, 관심영역:N)
          pParams.whlInqrYn = 'Y'
          // 컨설턴트 번호
          pParams.cnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId

        }else{
          // 분류목록
          trnstId = 'txTSSSA09S1'
          pParams.untySlmtClsfCd = pClsfSymtId
        }

        const _auth = 'S'

        this.post(lv_vm, pParams, trnstId, _auth)
          .then(function (response) {
            lv_vm.isAfterFirstSearch = true
            if (lv_vm.$commonUtil.isSuccess(response)) {
              // 서비스 후처리
              if (response.body !== null && response.body !== '') {
                // 서비스 호출 결과(body)가 있을 경우
                // 응답 데이터 처리
                let reponseBody = response.body
                 console.log( '@@@ 카테고리 조회 fn_SelClsfSymtCntnt  reponseBody >>', reponseBody)

                // 응답 데이터 처리 그리드용 추가 2023-2-7/
                // reponseBody.iCCntntBasInfoVO.forEach(async (item, index) => {
                //   item.blobImgUrl = ''
                //   if (process.env.NODE_ENV === 'local') {
                //     item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                //   } else if (process.env.NODE_ENV === 'development') {
                //     item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                //   } else {
                //     if (responseObj !== null) {
                //       let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                //       let responseObj = await lv_vm.$bizUtil.httpUrlCall(strUrl)
                //       if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                //         item.blobImgUrl = responseObj
                //       } else {
                //         item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                //       }
                //     } else {
                //       item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                //     }
                //   }
                // })

//2023.05/18변경
             reponseBody.iCCntntBasInfoVO.forEach(async (item, index) => {

                item.blobImgUrl = ''
                if (process.env.NODE_ENV === 'local') {
                  item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                } else if (process.env.NODE_ENV === 'development') {
                  item.blobImgUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=/img_1.png'
                } else {
                  let responseObj = null
                  if (item.thumApndFilePathNm !== null && item.thumApndFilePathNm !== '' && item.thumApndFileStoreNm !== null && item.thumApndFileStoreNm !== '') {
                    let strUrl = '/filedown.do?path=UNTY_SLMT_DIR&filename=' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
                    responseObj = await lv_vm.$bizUtil.httpUrlCall(strUrl)
                  }
                  if (responseObj) {
                    item.blobImgUrl = responseObj
                  } else {
                    item.blobImgUrl = require('@/assets/img/list/img-' + (Math.floor(Math.random() * 3) + 1) + '.png')
                  }
                }
              })


                // 동영상 테스트 [START]
                // reponseBody.iCCntntBasInfoVO.forEach((item, index) => { // 썸네일 이미지 설정, 썸네일 이미지가 없을 경우 기본 이미지 3개를 랜덤하게 노출
                //   if (item.untySlmtNm.indexOf('[운세]') > -1) {
                //     item.vdoLnkUrl = 'https://m.youtube.com/watch?v=bZg63ubtH2o'
                //     item.pdfFileYn = 'N'
                //   }
                // }) // 동영상 [END]

                // 서버 데이터 가져오기
                if(lv_vm.stndKeyVal === 'start'){
                  lv_vm.lv_ClsfSymtCntntBasInfoVOList = reponseBody.iCCntntBasInfoVO

                }else{
                  lv_vm.lv_ClsfSymtCntntBasInfoVOList = lv_vm.lv_ClsfSymtCntntBasInfoVOList.concat(reponseBody.iCCntntBasInfoVO)
                }

                // 서비스 통신 조회키
                if (response.trnstComm.stndKeyList != null && response.trnstComm.stndKeyList.length > 0) {
                  lv_vm.stndKeyList[0] = response.trnstComm.stndKeyList[0]
                } else {
                  lv_vm.stndKeyList = []
                }

                // 검색 후, 전체 데이터를 다시 보여주기 위해 저장
                lv_vm.lv_ClsfSymtCntntItems = lv_vm.lv_ClsfSymtCntntBasInfoVOList.slice()

                 
                // 더보기 키값
                if (lv_vm.stndKeyList !== null && lv_vm.stndKeyList.length > 0 && lv_vm.stndKeyList[0] !== null && lv_vm.stndKeyList[0].stndKeyVal !== null && lv_vm.stndKeyList[0].stndKeyVal.trim() !== '') {
                  // 다음페이지 데이터 존재함
                  lv_vm.stndKeyVal = 'more'
                } else {
                  // 다음페이지 데이터 존재안함(마지막 페이지)
                  lv_vm.stndKeyVal = 'end'
                }
                
                // 데이터가 응답(완료)되었으므로 false
                // lv_vm.lv_IsClsfSymtCntntLoading = false
              } else {
                // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
              }
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_vm.$addSnackbar('[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }

          }, function (error) {
            // 서버 에러 처리
            return Promise.reject(error)
          })
          .catch(function (error) {
            window.vue.error(error)
          })
          console.log( '@@@ 카테고리 조회 fn_SelClsfSymtCntnt end')
      },

      /*********************************************************
      * Function명: fn_CheckClick
      * 설명: 체크박스 선택
      * Params: object
      * Return: N/A
      *********************************************************/
      fn_CheckClick(target) {
        
        // 문자버튼 컨트롤을 위한 예외 카운트
        let doNotSmsCnt = 0
        let doNotDetailShowCnt = 0        

        if (this.lv_SelectedItemList.length > 0) {
          this.open()
          //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, true)
        }else {
          this.close()
          //this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
        }
// 2차오픈시 문자/이메일만 5개 선택 제한으로 변경
        //  if (this.lv_SelectedItemList.length > 5) {
        //    this.lv_SelectedItemList = this.lv_SelectedItemList.slice(0, SpConstants.MAX_SELECTION_ITEMS)
        //    this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
        //  }

        // 문자발송버튼 클릭 가능여부 체크
        // 동영상 자료 문자발송 가능하게 변경 (next사랑온미니2차) 

        for (let l = 0; l < this.lv_SelectedItemList.length; l++) {
          if (this.lv_SelectedItemList[l].vdoLnkUrl !== undefined && this.lv_SelectedItemList[l].vdoLnkUrl !== null && this.lv_SelectedItemList[l].vdoLnkUrl !== '') {
            doNotSmsCnt++
          }
          if (this.lv_SelectedItemList[l].untySlmtClsfNm01.indexOf('라이브러리') !== -1 || this.lv_SelectedItemList[l].untySlmtClsfNm01.indexOf('사랑On도움방') !== -1) { 
            doNotDetailShowCnt++
          }
        }

        if (doNotSmsCnt > 0) {
          this.lv_IsSelectedSmsContent = true
          if (this.lv_SelectedItemList.length !== doNotSmsCnt) {
              this.getStore('toast').dispatch('ADD', '동영상은 다른 안내자료와 함께 전송할 수 없습니다.')
          }    
        }else{
          this.lv_IsSelectedSmsContent = false
        }

        if (doNotDetailShowCnt > 0) {
           this.getStore('toast').dispatch('ADD', '선택항목 중 라이브러리, 도움방 자료가 포함되면 상세보기만 가능합니다.')
           this.lv_IsSelectedLibraryContent = true
        }else{
           this.lv_IsSelectedLibraryContent = false
        }      
      },

      /*********************************************************
      * Function명: fn_SMSBtnClicked
      * 설명: "문자전송" 버튼 클릭시
      * Params:  N/A
      * Return: N/A
      *********************************************************/
      fn_SMSBtnClicked() {

        if (this.lv_SelectedItemList.length > 5) {
           this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
           return
        }
        
        let lv_vm = this
        let pParams = {}

        const trnstId = 'txTSSSA01S8'
        const auth = 'S'
        this.post(lv_vm, pParams, trnstId, auth)
          .then(function (response) {
            if (lv_vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                if (response.body.startDt !== undefined && response.body.startDt !== '') {
                  if (new Date() >= new Date(response.body.startDt) && new Date() <= new Date(response.body.endDt)) {
                    lv_vm.$addSnackbar(response.body.msg)
                  } else {
                    // 값을 제대로 읽었으면 SMS 고객선택 팝업 호출
                    lv_vm.fn_OpenPopSmsCustList()
                  }
                } else {
                  // 서버 config 키값이 정의 되어 있지 않더라도 SMS 고객선택 팝업 호출
                  lv_vm.fn_OpenPopSmsCustList()
                }
              }
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
        
      },

      /******************************************************************************
      * Function명 : fn_IncrementItemInqrFgrList
      * 설명       : 조회 이력 저장
      * Params     : N/A
      * Return     : N/A
      ******************************************************************************/
      fn_IncrementItemInqrFgrList (video) {

        console.log('■■■■■ MSPSA207M ■■■■■  조회 이력 저장 (fn_IncrementItemInqrFgrList) START... ☞ ' + new Date().getTime())
        if (video ===  'Y') {
            this.fn_video_bottomsheet_close()    // 동영상 실행 버텀시트 close 처리
        }

        let lv_vm = this
        const trnstId = 'txTSSSA01P2'
        const auth = 'S'

        let sParams = {}
        // 컨설턴트 번호
        sParams.cnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId
        if (lv_vm.lv_IsMadeMultiItem) {
          sParams.sACntntBasInfoSVO = lv_vm.lv_SelectedItemList
        } else {
          let tempVOList = []
          tempVOList.push(lv_vm.lv_SelectedSingleItem)
          sParams.sACntntBasInfoSVO = tempVOList
        }

        this.post(lv_vm, sParams, trnstId, auth)
          .then(function (response) {
          // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
            lv_vm.getStore('progress').getters.getState.isShow = true
            if (lv_vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') {

                if (response.body.sASelCntntDtlSVO != null && response.body.sASelCntntDtlSVO.length > 0) {
                  if (response.body.sASelCntntDtlSVO.length === sParams.sACntntBasInfoSVO.length) {
                    // 선택 파일이 단일 파일이면서, 동영상 파일일 경우는 외부 링크 연결, 그렇지 않을 경우는 PDF Viewer 실행
                    if (!lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== null && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== undefined && lv_vm.lv_SelectedSingleItem.vdoLnkUrl !== '') { // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출
                      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                      lv_vm.getStore('progress').getters.getState.isShow = false
                      window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedSingleItem.vdoLnkUrl}, () => {}, () => {})
                    } else if (lv_vm.lv_IsMadeMultiItem && lv_vm.lv_SelectedItemList.length === 1 && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== null && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== undefined && lv_vm.lv_SelectedItemList[0].vdoLnkUrl !== '') {
                      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                      lv_vm.getStore('progress').getters.getState.isShow = false
                      window.fdpbridge.exec('intentBrowserPlugin', {uri: lv_vm.lv_SelectedItemList[0].vdoLnkUrl}, () => {}, () => {})
                    } else {
                      lv_vm.fn_SaveFOMFile()
                    }
                  }
                }
              } else {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                lv_vm.getStore('progress').getters.getState.isShow = false
                if (response.msgComm.msgCd !== null && response.msgComm.msgCd.length > 0) {
                  lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
                }
              }
            }
          })
          .catch(function (error) {
            // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
            lv_vm.getStore('progress').getters.getState.isShow = false
            // if (lv_vm.getStore('progress').getters.getState.isShow !== null && lv_vm.getStore('progress').getters.getState.isShow === true) {
            //   lv_vm.getStore('progress').getters.getState.isShow = false
            // }
            window.vue.error(error)
          })
          console.log('■■■■■ MSPSA207M ■■■■■  조회 이력 저장 (fn_IncrementItemInqrFgrList) end ... ☞ ' + new Date().getTime())
      },


      /******************************************************************************
      * Function명 : fn_BtnESaveFile
      * 설명       : 단 건의 첨부파일을 실행(저장/오픈)한다.
      ******************************************************************************/
      async fn_SaveFOMFile () {

        if (process.env.NODE_ENV === 'local') {
          let viewerTargetFileList = ''
          let savePdfTargetItemList = []

          if (this.lv_IsMadeMultiItem) {
            this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.eltrnDoctFileNm = 'test.pdf'
              itemObj.localFileNm = 'test.pdf'
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

              savePdfTargetItemList.push(itemObj)
            })
          } else {
            let selectedUntySlmtItem = this.lv_SelectedSingleItem
            let itemObj = {}
            itemObj.eltrnDoctFilePathNm = selectedUntySlmtItem.pdfApndFilePathNm
            itemObj.eltrnDoctFileNm = 'test.pdf'
            itemObj.localFileNm = 'test.pdf'
            itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

            savePdfTargetItemList.push(itemObj)
          }

          savePdfTargetItemList.forEach(item => {
            try {

              viewerTargetFileList += item.eltrnDoctFileNm + 'tssB' + item.fileTitle + 'tssA'
            } catch (error) {
              if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
            }
          })

          this.fn_CallPdfViewer(viewerTargetFileList)
        } else {
          let successCount = 0
          let tryCount = 0
          let savePdfTargetItemList = []
          let viewerTargetFileList = ''

          if (this.lv_IsMadeMultiItem === true) {
            this.lv_SelectedItemList.forEach(selectedUntySlmtItem => {
              if (selectedUntySlmtItem.pdfFileYn === 'Y') {
                let itemObj = {}
                itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
                itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
                itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
                itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

                savePdfTargetItemList.push(itemObj)
              } else {
                let tempDcFmlmtSVO = null
                let tempCompletedPdfSVO = null
                this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
                  if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                    tempDcFmlmtSVO = dcFmlmtSVO
                  }
                })

                if (tempDcFmlmtSVO !== null) {
                  this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                    if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                      tempCompletedPdfSVO = completedPDFFileSVO
                    }
                  })
                }

                if (tempCompletedPdfSVO !== null) {
                  let itemObj = {}
                  itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
                  itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm

                  // FOM 파일을 PDF로 변환했을 경우는 .pdf 확장자를 붙여서 파일을 다운받도록 설정
                  let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
                  if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                    itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                    itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                  } else {
                    itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                    itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
                  }

                  savePdfTargetItemList.push(itemObj)
                }
              }
            })
          } else {
            let selectedUntySlmtItem = this.lv_SelectedSingleItem
            if (selectedUntySlmtItem.pdfFileYn === 'Y') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
              itemObj.eltrnDoctFileNm = selectedUntySlmtItem.pdfApndFilePathNm + '/' + selectedUntySlmtItem.pdfApndFileStoreNm
              itemObj.localFileNm = selectedUntySlmtItem.pdfApndFileStoreNm

              savePdfTargetItemList.push(itemObj)
            } else {
              let tempDcFmlmtSVO = null
              let tempCompletedPdfSVO = null
              this.lv_SADCFmlmtSVOList.forEach(dcFmlmtSVO => {
                if (selectedUntySlmtItem.untySlmtId === dcFmlmtSVO.untySlmtId) {
                  tempDcFmlmtSVO = dcFmlmtSVO
                }
              })

              if (tempDcFmlmtSVO !== null) {
                this.lv_CompletedPDFFileVOList.forEach(completedPDFFileSVO => {
                  if (tempDcFmlmtSVO.srcFileNm01 === completedPDFFileSVO.eltrnDoctId) {
                    tempCompletedPdfSVO = completedPDFFileSVO
                  }
                })
              }

              if (tempCompletedPdfSVO !== null) {
                let itemObj = {}
                itemObj.eltrnDoctFilePathNm = tempCompletedPdfSVO.eltrnDoctFilePathNm
                itemObj.fileTitle = selectedUntySlmtItem.untySlmtNm
                let tempDateDir = tempCompletedPdfSVO.lstTrtDtm.slice(0, 10).replace(/-/g, '')
                if (tempCompletedPdfSVO.eltrnDoctId.indexOf('.pdf') < 0) {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId + '.pdf'
                } else {
                  itemObj.eltrnDoctFileNm = tempDateDir + '/' + tempCompletedPdfSVO.eltrnDoctId
                  itemObj.localFileNm = tempCompletedPdfSVO.eltrnDoctId
                }

                savePdfTargetItemList.push(itemObj)
              }
            }
          }

          // savePdfTargetItemList.forEach(item => {
          //   if (process.env.NODE_ENV === 'development') {
          //     item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
          //     item.eltrnDoctFileNm = 'test.pdf'
          //     item.localFileNm = 'test.pdf'
          //     item.fileTitle = '[TEST]'
          //   }
          //   try {
          //     this.fn_FdpSaveFile(item)
          //       .then(response => {
          //         successCount++
          //         tryCount++

          //         viewerTargetFileList += response + 'tssB' + item.fileTitle + 'tssA'
          //         if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
          //           if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
          //             this.fn_CallPdfViewer(viewerTargetFileList)
          //           } else if (tryCount === this.lv_SelectedItemList.length) {
          //             // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          //             this.getStore('progress').getters.getState.isShow = false
          //             this.getStore('confirm').dispatch('ADD', '총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
          //           }
          //         } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
          //           this.fn_CallPdfViewer(viewerTargetFileList)
          //         }
          //       }).catch(error => {
          //         tryCount++
          //         // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
          //         // 다중 선택일 경우
          //         if (this.lv_IsMadeMultiItem) {
          //           // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
          //           if (tryCount === this.lv_SelectedItemList.length) {
          //           // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
          //             this.getStore('progress').getters.getState.isShow = false
          //             // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
          //             this.getStore('confirm').dispatch('ADD', '총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
          //           }

          //           if (SpConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSP201M ■■■■■ 다건 실패 : ' + new Error(error))
          //         } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
          //           // 화면 깜박거림을 방지하기 위한 로딩바를 해제
          //           this.getStore('progress').getters.getState.isShow = false
          //           // 오류 팝업 메시지 출력
          //           this.getStore('confirm').dispatch('ADD', '총 1건 중 1건 저장이 실패하였습니다')
          //         }
          //         if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          //       })
          //   } catch (error) {
          //     if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          //   }
          // })

        for(let i=0; i<savePdfTargetItemList.length; i++){
          try {
            if (process.env.NODE_ENV === 'development') {
              item.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              item.eltrnDoctFileNm = 'test.pdf'
              item.localFileNm = 'test.pdf'
              item.fileTitle = '[TEST]'
            }

            await this.fn_FdpSaveFile(savePdfTargetItemList[i]).then(response => {
              successCount++
              tryCount++
              console.log('fn_FdpSaveFile  test-2 >' , successCount ,tryCount, savePdfTargetItemList[i] )
              viewerTargetFileList += response + 'tssB' + savePdfTargetItemList[i].fileTitle + 'tssA'
              if (this.lv_IsMadeMultiItem) { // 다중 선택일 경우는 선택 파일 개수와 성공 및 시도 횟수가 일치할 경우 Viewer 실행
              console.log('fn_FdpSaveFile  test-3 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                if (successCount === this.lv_SelectedItemList.length && successCount === tryCount) {
                  this.fn_CallPdfViewer(viewerTargetFileList)
                } else if (tryCount === this.lv_SelectedItemList.length) {
                  console.log('fn_FdpSaveFile  test-4 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                  //this.getStore('progress').getters.getState.isShow = false
                  this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                }
              } else { // 단일 선택일 경우는 바로 PDF Viewer 호출
                  console.log('fn_FdpSaveFile  test-10 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                this.fn_CallPdfViewer(viewerTargetFileList)
              }
            }).catch(error => {
              tryCount++
              // window.alert('파일 다운로드 실패 콜백 : ' + new Error(error))
              // 다중 선택일 경우
              if (this.lv_IsMadeMultiItem) {
                                console.log('fn_FdpSaveFile  test-5 >' , successCount ,tryCount, savePdfTargetItemList[i] )
                // 시도 횟수와 선택한 파일 개수가 일치할 경우에만 오류 메시지 출력
                if (tryCount === this.lv_SelectedItemList.length) {
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
                  this.getStore('progress').getters.getState.isShow = false
                  // 자료 선택 건수에 실패 건수를 오류 메시지로 출력
                  this.$addSnackbar('총 ' + this.lv_SelectedItemList.length + '건 중 ' + (tryCount - successCount) + '건 저장이 실패하였습니다')
                }

                if (SpConstants.DEBUG_MODE_CON) window.console.log('■■■■■ TSSSP201M ■■■■■ 다건 실패 : ' + new Error(error))
              } else { // 단일 선택에 오류 발생 시 시도 횟수는 비교하지 않음.
                // 화면 깜박거림을 방지하기 위한 로딩바를 해제
                this.getStore('progress').getters.getState.isShow = false
                // 오류 팝업 메시지 출력
                this.$addSnackbar('총 1건 중 1건 저장이 실패하였습니다')
              }
              if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
            })
          } catch (error) {
            if (SpConstants.DEBUG_MODE_CON) window.console.log(new Error(error))
          }
         }

        }
      },
     /******************************************************************************
      * Function명 : fn_FdpSaveFile
      * 설명       : 첨부파일을 저장한다.
      ******************************************************************************/
      fn_FdpSaveFile (obj) {
        return new Promise((resolve, reject) => {
          // .pdf 확장자가 없을 경우 확장자 첨부
          if (obj.localFileNm.indexOf('.pdf') < 0) {
            obj.localFileNm = obj.localFileNm + '.pdf'
          }
          window.fdpbridge.exec('downloadPlugin', {
            path: obj.eltrnDoctFilePathNm,
            filename: obj.eltrnDoctFileNm, // src Directory + fileName
            type: 'pdf',
            localpath: '/sli/tss/comm/fmlmt', // local Directory(안내 자료는 fmlmt 폴더 사용)
            localfilename: obj.localFileNm, // local fileName
            rename: 'N'
          }, (result) => {
            resolve(result.data)
          }, (result) => {
            reject(result.data)
          })
        })
      },

      /******************************************************************************
      * Function명 : fn_CallPdfViewer
      * 설명       : PDF뷰어 호출
      * 인자       : Array
      * Return     : void
      ******************************************************************************/
      fn_CallPdfViewer (viewerTargetFileList) {
        // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
        this.getStore('progress').getters.getState.isShow = false
        let libraryItemCount = 0
        if (this.lv_IsMadeMultiItem) {
          this.lv_SelectedItemList.forEach(item => {
            if (item.untySlmtClsfNm01.indexOf('라이브러리') > -1 || item.untySlmtClsfNm01.indexOf('사랑On도움방') > -1 || item.untySlmtNm.indexOf('라이브러리') > -1 || item.untySlmtNm.indexOf('사랑On도움방') > -1) {
              libraryItemCount++
            }
           
          })
        } else {
          if (this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf('라이브러리') > -1 || this.lv_SelectedSingleItem.untySlmtClsfNm01.indexOf('사랑On도움방') > -1 || this.lv_SelectedSingleItem.untySlmtNm.indexOf('라이브러리') > -1 || this.lv_SelectedSingleItem.untySlmtNm.indexOf('사랑On도움방') > -1) {
            libraryItemCount++
          }
        }
        //안쓸예정임 pk.son
        // 대분류명이 "라이브러리" 형태가 아닌 "(p)" 등이 끝에 붙어올수 있다. 이를 고려함.
        // lv_SelcectedClsfSytmNm 변수는 swipe 탭에서 "라이브러리" or "사랑On도움방" 탭을 선택후 pdf 뷰 보기를 할경우임.
        let menuAuth = ''
        if (libraryItemCount > 0) { // 테블릿과 별도 구현, 동기화시 주의!!!!!
         menuAuth = '0000' // 안드로이드 pdf 뷰에서 "SMS" 메뉴 숨기기(다중 선택시 "라이브러리" or "사랑On도움방" 포함되면 선택된 모든 파일에 대해서도 보이지 않음)
        } else {
         menuAuth = '1111'
        }

        let lv_vm = this
        // PDF 뷰어 호출
        window.fdpbridge.exec('callPdfViewerPlugin', {
          path: '/sli/tss/comm/fmlmt',
          menu: menuAuth, // 대메뉴가 "라이브러리" or "사랑On도움방" 일때 안드로이드 앱의 pdf 뷰's "SMS" 메뉴는 않보이게 한다.
          file: viewerTargetFileList, // 서버에 올릴때.
          isDownloadBtn: 'Y' // 안내자료에서는 무조건 Y
          // file: 'EST_20th.pdftssB제목1tssAtest1234567890.pdftssB제목2tssAtest.pdftssB제목3tssAEST_20th2.pdftssB제목4tssAtest-123.pdftssB제목5tssAEST_20th012345678901234567890123456789.pdftssB제목5tssA' // 테스트
        }, function (result) {
          // 성공콜백
          // 스마트폰은 SMS 팝업창을 바로 호출하자. 이때 btn1 이고 pdf뷰의 'X'표시를 클릭시는 'cancel' 값이 온다.
          if (result.data === 'btn1') {
            //this.lv_IsSelectedLibraryContent === true 체크해서 SMS발송 막아야함 pk.son
            // if (lv_vm.lv_IsSearch) lv_vm.fn_SMSBtnClicked('TSSSP205M')
            lv_vm.fn_SMSBtnClicked()   //문자
          } else if (result.data === 'btn2') {
                  lv_vm.fn_OpenPopEmailCustList()  //이메일
          } else if (result.data === 'btn3') {
                  lv_vm.fn_print_BottomSheet_open() //인쇄
          } else if (result.data === 'btn4') {
                  lv_vm.fn_OpenMSPSA210P()          
          }
        }, function (result) {
          // 실패콜백
          if (SpConstants.DEBUG_MODE_CON) console.log('실패콜백=' + result.data)
        })
      },
      /******************************************************************************
      * Function명 : fn_CreateToPDFContnt
      * 설명       : PDF 파일 목록 생성 - 미리보기
      * 인자       : N/A
      * Return     : void
      ******************************************************************************/
      fn_CreateToPDFContnt () {

         console.log('■■■■■ MSPSA207M ■■■■■ PDF 생성 요청(fn_CreateToPDFContnt) START ☞ ' + new Date().getTime())
        let lv_vm = this

        lv_vm.lv_SADCFmlmtSVOList = []
        lv_vm.lv_CompletedPDFFileVOList = []

        let pParams = {}
        pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId

        if (lv_vm.lv_IsMadeMultiItem) {
          // FOM 파일만 PDF 생성 대상
          pParams.sACntntBasInfoSVO = lv_vm.lv_SelectedFomItemList
        } else {
          let tempVOList = []
          tempVOList.push(lv_vm.lv_SelectedSingleItem)
          pParams.sACntntBasInfoSVO = tempVOList
        }
        const trnstId = 'txTSSSA22P3'
        const auth = 'S'
        this.post(lv_vm, pParams, trnstId, auth)
          .then(function (response) {
            // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            if (lv_vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '' && response.body.sADCFmlmtSVO != null && response.body.sADCFmlmtSVO.length > 0) {
                // 서비스 호출 결과(body)가 있을 경우
                // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                //lv_vm.getStore('progress').getters.getState.isShow = true
                lv_vm.lv_SADCFmlmtSVOList = response.body.sADCFmlmtSVO
                 console.log('■■■■■ MSPSA207M ■■■■■ PDF 생성 요청(fn_CreateToPDFContnt) data 문서id 확인>>> ' , lv_vm.lv_SADCFmlmtSVOList)
                lv_vm.lv_CheckDocsRetryNumberOfTimes = 0 // PDF 생성여부 확인 조회 회수 초기화
                lv_vm.fn_CheckContentsTransformation(lv_vm.lv_SADCFmlmtSVOList)
              } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
                //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                //lv_vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              }
            } else { // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              //lv_vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /******************************************************************************
      * Function명 : fn_CheckContentsTransformation
      * 설명       : FOM 파일을 PDF 파일로 변환 요청 후 요청한 PDF 생성 여부 확인
      * Params     : 변환 요청한 결과 VO 목록
      * Return     : N/A
      ******************************************************************************/
      fn_CheckContentsTransformation (pSADCFmlmtSVO) {
        console.log('■■■■■ MSPSA207M ■■■■■ (fn_CheckContentsTransformation) START ☞ ' + new Date().getTime())

        let lv_vm = this
        let pParams = {
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
          sADCFmlmtSVO: pSADCFmlmtSVO
        }
        const trnstId = 'txTSSSA22P4'
        const auth = 'S'


        this.post(lv_vm, pParams, trnstId, auth)
          .then(function (response) {
            if (lv_vm.$commonUtil.isSuccess(response)) {
              if (response.body !== null && response.body !== '') {
                // 서비스 호출 결과(body)가 있을 경우
                // 생성여부 확인 시도 회수를 증가
                // 20번까지만 생성확인 여부 시도
                lv_vm.lv_CheckDocsRetryNumberOfTimes++
                console.log('■■■■■ MSPSA207M ■■■■■ (fn_CheckContentsTransformation) pdf변환 정보 >>  ☞ ' , response.body.sADCDoctTrfoBasSVO)
                // FOM 파일 목록에 대한 생성여부를 결과 VO로 리턴
                // VO로 리턴된 FOM 파일 이외의 FOM파일은 계속 생성 여부를 확인
                if (response.body.sADCDoctTrfoBasSVO !== null && response.body.sADCDoctTrfoBasSVO.length > 0) {
                  let tempPSADCFmlmtSVO = []
                  let resultSADCDoctTrfoBasSVO = response.body.sADCDoctTrfoBasSVO.slice()

                  // 생성요청 VO 목록에서
                  lv_vm.lv_SADCFmlmtSVOList.forEach(dcItem => {
                    let tempFlag = false
                    let tempVO = {}

                    // 생성여부 확인된 VO 목록의 결과를 비교하여 일치하는 VO가 있을 경우
                    resultSADCDoctTrfoBasSVO.forEach(item => {
                      if (dcItem.srcFileNm01 === item.eltrnDoctId) {
                        tempFlag = true
                        tempVO = item
                      }
                    })

                    // 생성여부 확인 VO를 생성여부 확인된 VO만 별도로 저장하고 있는 VO에 저장
                    if (tempFlag) {
                      if (lv_vm.lv_CompletedPDFFileVOList !== null) {
                        let addFlag = true
                        lv_vm.lv_CompletedPDFFileVOList.forEach(item => {
                          if (tempVO.eltrnDoctId === item.eltrnDoctId) {
                            addFlag = false
                          }
                        })

                        if (addFlag) {
                          lv_vm.lv_CompletedPDFFileVOList.push(tempVO)
                        }
                      }
                    } else {
                      tempPSADCFmlmtSVO.push(dcItem)
                    }
                  })

                  // 생성여부 확인이 끝난 FOM 파일만 저장하고 있는 Array와 생성요청 FOM 파일 정보를 담고 있는 VO 목록이 일치하지 않으면
                  // 생성여부 확인 작업을 계속 진행
                  if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                    // 20번 시도 회수보다 작으면 생성여부 확인 작업을 계속 시도
                    if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                      setTimeout(function () {
                        lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                      }, 1000)
                    } else { // 그렇지 않으면 시도 중지
                      // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                      //v_Vm.getStore('progress').getters.getState.isShow = false
                      // 조회이력 증가 호출
                      //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                      //lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
                    }
                  } else { // 그렇지 않으면 조회수 증가 단계로 이동
                    // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                    //lv_vm.getStore('progress').getters.getState.isShow = false
                    // 조회이력 증가 호출
                    if (lv_vm.isNofacCnsl) { // [비대면상담]인 경우는 TUTU00012, TUTU00013 테이블에 상담 문서 정보 INSERT
                       for (let i = 0; i < pSADCFmlmtSVO.length; i++) {
                         let tempObj = {}
                         tempObj.nofacCnslDoctNm = pSADCFmlmtSVO[i].prdtNm
                         tempObj.eltrnDoctFilePathNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFilePathNm
                         tempObj.eltrnDoctFileNm = response.body.sADCDoctTrfoBasSVO[i].eltrnDoctFileNm
                         lv_vm.lv_SelectedPdfList.push(tempObj)
                       }
                       lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
                    } else { // 조회이력 증가 호출
                      lv_vm.fn_IncrementItemInqrFgrList()
                    }
                  }  
                } else if (lv_vm.lv_CheckDocsRetryNumberOfTimes < 20) {
                  setTimeout(function () {
                    lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                  }, 1000)
                } else {
                  // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 출력
                  lv_vm.getStore('progress').getters.getState.isShow = false
                  // 조회이력 증가 호출
                  //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                  lv_vm.getStore('confirm').dispatch('ADD', 'PDF 변환에 실패하였습니다.')
                }
              } else { // 서비스 호출 결과는 없지만
                // 변환 완료된 PDF 목록과 변환 요청 PDF 목록 개수가 일치하지 않을 경우 변화여부 확인 요청 시도
                if (lv_vm.lv_CompletedPDFFileVOList.length !== lv_vm.lv_SADCFmlmtSVOList.length) {
                  setTimeout(function () {
                    lv_vm.fn_CheckContentsTransformation(pParams.sADCFmlmtSVO)
                  }, 1000)
                }
              }
            }
          })
          .catch(function (error) {
            // 화면 깜박거림을 방지하기 위해 강제로 로딩바를 해제
            //lv_vm.getStore('progress').getters.getState.isShow = false
            window.vue.error(error)
          })
          console.log('■■■■■ MSPSA207M ■■■■■ (fn_CheckContentsTransformation) END ☞ ' + new Date().getTime())

      },
      
      /*********************************************************
      * Function명: fn_GetSearchListItemCount
      * 설명: 카테고리 별 건 수 체크
      * Params: String
      * Return: int
      *********************************************************/
      fn_GetSearchListItemCount (v) {
        let cnt = 0
        for (let i = 0; i < this.lv_CmpxCondCntntItemList.length; i++) {
          if (v === this.lv_CmpxCondCntntItemList[i].key) {
            cnt = this.lv_CmpxCondCntntItemList[i].iCCntntBasInfoVOItems.length
            break
          }
        }
        return cnt
      },

      /*********************************************************
      * Function명: fn_GetSearchList
      * 설명: list mapping 용도
      * Params: String
      * Return: 
      *********************************************************/
      fn_GetSearchList (v) {
        return this.lv_CmpxCondCntntItemList[v].iCCntntBasInfoVOItems
      },
      // fn_GetImageFullPath (item, index) {
      //   let imgFullPath = ''

      //   if (process.env.NODE_ENV === 'local') {
      //     imgFullPath = require('@/assets/img/img_' + ((index % 3) + 1) + '.png')
      //   } else if (process.env.NODE_ENV === 'development') {
      //     imgFullPath = '/APP/temp_BOARD/img_' + ((index % 3) + 1) + '.png'
      //   } else {
      //     imgFullPath = '/filedown.do?path=UNTY_SLMT_DIR&filename=/' + item.thumApndFilePathNm + '/' + item.thumApndFileStoreNm
      //   }
      //   return imgFullPath
      // },
      
      /*********************************************************
      * Function명: fn_OnSelectedSingleItem
      * 설명: 단건 상세보기
      * Params: Object
      * Return: N/A
      *********************************************************/
      fn_OnSelectedSingleItem (pSelectedItem) {
        // pSelectedItem.checked = false
        this.clickedSingleItem = true
        this.lv_SelectedSingleItem = pSelectedItem
        
        this.lv_SelectedItemList = []      //상세보기 - 더보기 시 사용
        // this.$bizUtil.showCustomBottomSheet(this, this.$refs.bs10, false)
        this.lv_SelectedItemList.push(pSelectedItem) //상세보기 - 더보기 시 사용

        this.lv_IsMadeMultiItem = false
        if (pSelectedItem.vdoLnkUrl !== null && pSelectedItem.vdoLnkUrl !== undefined && pSelectedItem.vdoLnkUrl !== '') {
          // 동영상 링크일 경우 해당 경로를 이용해 브라우저 호출          
          this.fn_video_bottomsheet_open()  //'동영상 실행시 데이터 요금이 발생될수 있으니 유의 바랍니다')
        } else if (pSelectedItem.pdfFileYn === 'Y') {
          this.fn_IncrementItemInqrFgrList()
        } else {
          // 동영상 링크가 아닐 경우는 인쇄 실행
          this.fn_CreateToPDFContnt()
        }

        // FOM 파일 저장
        this.lv_SelectedFomItemList.push(pSelectedItem)
      },

      
      /*********************************************************
      * Function명: fn_PDFDetailBtnClicked
      * 설명: 상세보기버튼 클릭 이벤트 핸들러
      * Params: N/A
      * Return: N/A
      *********************************************************/
      fn_PDFDetailBtnClicked () {
        // 동기화 실행.
        this.fn_OnCheckUntySlmtItemCnt(this.lv_SelectedItemList)
        // 상세보기 버튼 클릭 이벤트 발생
        this.fn_ReadySelectedItemsViewDetails()
      },


      /******************************************************************************
      * Function명: fn_OnCheckUntySlmtItemCnt
      * 설명: 상세보기시 변수세팅
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_OnCheckUntySlmtItemCnt (items) {
        if ( items === undefined || items === null ) {
          return
        }
        this.lv_ChkCnt = items.length
        // this.lv_SelectedItemList.length = 0 // 문제없음.
        // this.lv_SelectedItemList = [] // 메모리릭있으면 풀어볼것 위에거랑 세트
        this.lv_SelectedItemList = items
      },


      /******************************************************************************
      * Function명: fn_ReadySelectedItemsViewDetails
      * 설명: 안내자료_홈 화면에서 썸네일 또는 타이틀 , 인기자료 항목 선택시 PDF 보기
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      fn_ReadySelectedItemsViewDetails () {
        // 동영상 자료 단건 선택 후 '상세보기' 버튼 클릭 시는 조회수 증가 후 URL 이동
        if (this.lv_SelectedItemList.length === 1 && this.lv_SelectedItemList[0].vdoLnkUrl !== null && this.lv_SelectedItemList[0].vdoLnkUrl !== '' && this.lv_SelectedItemList[0].vdoLnkUrl !== undefined) {
          this.fn_IncrementItemInqrFgrList()
        } else {
 //         if (this.lv_SelectedItemList.length <= SpConstants.MAX_SELECTION_ITEMS) {   //  2차개발시 상세보기 건수 제한 중지함.-기획
            // 동영영 자료 선택 여부 판단
            let vodItemCount = 0
            this.lv_SelectedItemList.forEach(item => {
              if (item.vdoLnkUrl !== null && item.vdoLnkUrl !== '' && item.vdoLnkUrl !== undefined) {
                vodItemCount++
              }
            })

            // 동영상 자료 선택 후 상세보기 진행 시 경고 메시지 출력
            if (vodItemCount > 0) {
              window.fdpbridge.exec('ToastPlugin', {message: '동영상 자료는 상세보기가 불가합니다.\n동영상자료 선택 해제 후 다시 진행해 주세요..'}, () => {}, () => {})
            } else {
              // 아이폰 체크 로직 제외요청 (2023/05/11)
              // if(this.$commonUtil.getIsIOS() && this.lv_SelectedItemList.length > 1){
              //   this.alertPop = this.$bottomModal.open(MSPBottomAlert, {
              //     properties: {
              //       single: true,
              //       title: "알림",
              //       content: '아이폰의 경우, 안내자료 상세보기는 1개씩 가능합니다.',
              //       title_pos_btn: "확인"
              //     },
              //     listeners: {
              //       onPopupConfirm: () => {
              //         console.log('onPopupConfirm call')
              //         this.$bottomModal.close(this.alertPop)
              //       },
              //       onPopupCancel: () => {
              //         console.log('onPopupCancel call')
              //         this.$bottomModal.close(this.alertPop)
              //       },
              //       onPopupClose: () => {
              //         console.log('onPopupClose call')
              //         this.$bottomModal.close(this.alertPop)
              //       }
              //     }
              //   });
              //   return
              // }

              // 다중 선택 후 상세보기 진행시
              this.lv_IsMadeMultiItem = true
              this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화

              // 로딩바 강제 설정
              this.getStore('progress').getters.getState.isShow = true
              this.lv_SelectedItemList.forEach(item => {
                if (item.pdfFileYn === 'Y') {
                // pdfFileCount++
                } else {
                  this.lv_SelectedFomItemList.push(item) // FOM 파일 저장
                }
              })

              // if (pdfFileCount > 0 && pdfFileCount !== this.lv_SelectedItemList.length) { // FOM 파일과 PDF 파일이 동시에 선택되었을 경우
              // } else if (pdfFileCount > 0 && pdfFileCount !== this.lv_SelectedItemList.length) { // 선택된 파일이 PDF 파일만 있을 경우는 인터페이스를 호출하지 않고 바로 Viewer 호출
              // } else if (pdfFileCount === 0 && this.lv_SelectedItemList.length > 0) { // 선택된 파일들 중 PDF 파일 형식이 아닐 경우에만..FOM 파일만 선택했을때
              // FOM 파일과 PDF 파일 동시 선택 시 모두 상세보기 및 출력, SMS, 이메일 발송 가능

              if (this.lv_SelectedFomItemList.length > 0) {
                this.fn_CreateToPDFContnt()
              } else {
                this.fn_IncrementItemInqrFgrList()
              }
            }
//          } else {
//          }
        }
      },
    
    /*********************************************************
     * Function명: fn_SaveToCouncel  (화상상담 자료저장)
     * 설명: 고객목록 팝업에서 고객 선택 후 저장 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록(List<SAGrpInfoSVO>)
     * Return: N/A
     *********************************************************/
    fn_SaveToCouncel() {
     
     console.log('@@@ MSPSA207M >>>  화상상담자료저장 fn_SaveToCouncel START  ')

      this.isNofacCnsl = true

      let parentsViewId = 'MSPSA207M'
      let custList = this.lv_SelectedCustList.slice()
      this.lv_TargetType = 'c'
        // 수신고객목록에 전달할 선택 고객목록 및 기타 파라미터 설정
      this.custListObj = [
          {targetType: this.lv_TargetType, parentsViewId: parentsViewId, custList: custList}
      ]

      this.fn_AfterCustSearch() // FOM문서 PDF변환 혹은 PDF문서 정보 저장
    },
    /*********************************************************
     * Function명: fn_AfterCustSearch
     * 설명: [비대면화상상담]고객목록 팝업에서 고객 선택 후 다음 버튼 클릭 시
     * Params: 고객목록 팝업에서 선택된 고객목록
     * Return: N/A
     *********************************************************/
    fn_AfterCustSearch() {
      // 다중 선택 후 화상상담 진행시
      this.lv_IsMadeMultiItem = true

      this.lv_SelectedFomItemList = [] // PDF로 변환될 FOM 파일 저장 목록 초기화
      this.lv_SelectedPdfList = [] // 기존에 만들어진 PDF파일 담을 목록 초기화
      let lv_vm = this
      // 로딩바 강제 설정
      this.getStore('progress').getters.getState.isShow = true
      console.log('■■■■■ MSPSA207M ■■■■■ 화상상담자료저장 전 선택항목 체크(fn_AfterCustSearch) START ☞ ' + new Date().getTime())

      // 1. FOM 파일만 별도로 추출
      //    FOM 파일은 문서변화 서버를 이용해 PDF로 변환
      // 2. PDF 파일일 경우
      //    'UNTY_SLMT_DIR' 를 명시적으로 지정해 주고
      //    pdfApndFilePathNm에 저장된 'ic/20190219' 와
      //    pdfApndFileStoreNm에 저장된 '30002012030102301203213'와 같은 파일명을 조합하여 파일을 다운로드(.pdf 확장자를 붙이지 않음)
      //    -> 최종경로 : /filedown.do?path=UNTY_SLMT_DIR&filename=ic/20190220/000300336246415221102209102
      console.log('********** MSPSA207M ****** lv_SelectedCnslItemList ☞ ', this.lv_SelectedItemList)

      this.lv_SelectedItemList.forEach(item => {
        if (item.pdfFileYn === 'Y') { // PDF 파일
          // 로컬 테스트인 경우
          if (process.env.NODE_ENV === 'local') {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR'
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              itemObj.eltrnDoctFileNm = item.pdfApndFileId // test용
              lv_vm.lv_SelectedPdfList.push(itemObj)
          } else {
              let itemObj = {}
              itemObj.eltrnDoctFilePathNm = 'UNTY_SLMT_DIR' // Context.properties에서 PDF 파일이 저장된 위치를 명시한 키값 설정
              itemObj.nofacCnslDoctNm = item.untySlmtNm
              // itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm // PDF 파일일 경우는 확장자 .pdf를 붙이지 않음
              itemObj.pdfFilePathDirNm = item.pdfApndFilePathNm // ic/20190219
              itemObj.eltrnDoctFileNm = item.pdfApndFileStoreNm
              lv_vm.lv_SelectedPdfList.push(itemObj)
          }
        } else {
             lv_vm.lv_SelectedFomItemList.push(item) // FOM 파일 저장
        }
      })

      // FOM 파일이 하나 이상 선택되었을 경우는 PDF 변환 진행
      if (lv_vm.lv_SelectedFomItemList.length > 0) {
          lv_vm.fn_CreateToPDFContnt()
      } else { // PDF문서만 선택 되었을 때
          lv_vm.fn_InsNofacCnslData(lv_vm.lv_SelectedPdfList)
      }
    },

    /******************************************************************************
    * Function명 : fn_InsNofacCnslData
    * 설명       : [비대면화상상담] 자료 저장
    * Params     : 비대면채널고객ID, 비대면상담문서ID, 비대면업무구분코드,비대면컨설턴트ID,문서삭제여부,비대면상담문서명,비대면상담문서경로명
    * Return     : 결과 코드(1:성공 0:실패)
    ******************************************************************************/
    fn_InsNofacCnslData(docInfo) {

      console.log('***** MSPSA207M *** PDF문서 저장 *** fn_InsNofacCnslData ☞ ', docInfo)
      let lv_vm = this
 
      lv_vm.isNofacCnsl = false // 초기화
      lv_vm.lv_TargetType = 'p' // 초기화
      let pParams = {}
      let ctNofacCnslListVO = []

      for (let i = 0; i < docInfo.length; i++) { // 문서 목록
        for (let j = 0; j < lv_vm.custListObj[0].custList.length; j++) { // 선택 고객 목록
          let pParam = {}
          pParam.nofacChnlCustId = lv_vm.custListObj[0].custList[j].chnlCustId // 비대면채널고객ID
          pParam.nofacCnslBusnScCd = 'SA' // 비대면업무구분코드(안내자료:SA)
          pParam.nofacCnsltNo = lv_vm.getStore('userInfo').getters.getUserInfo.userId // 비대면컨설턴트ID
          pParam.doctDeltYn = ' ' // 문서삭제여부
          pParam.nofacCnslDoctNm = docInfo[i].nofacCnslDoctNm // 비대면상담문서명
          pParam.nofacCnslDoctId = docInfo[i].eltrnDoctFileNm // 비대면상담문서ID
          pParam.nofacCnslDoctPathNm = docInfo[i].eltrnDoctFilePathNm // 비대면상담문서경로명
          pParam.pdfFilePathDirNm = docInfo[i].pdfFilePathDirNm // 기존PDF 파일 저장 경로명 예)ic/20190219
          ctNofacCnslListVO.push(pParam)
        }
      }

      pParams.ctNofacCnslListVO = ctNofacCnslListVO
      console.log('### MSPSA207M [비대면화상상담] 자료 저장 start>>' , pParams.ctNofacCnslListVO)

      const trnstId = 'txTSSCT80S1'
      const auth = 'S'

      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if (lv_vm.$commonUtil.isSuccess(response)) {
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
              if (response.body.resultCd === 1) {
                if (lv_vm.custListObj[0].custList.length === 1) {    // 화상상담 고객이 2명 이상이면 화상상담 자료목록 팝업 호출 
                  lv_vm.lv_selCust = {}
                  lv_vm.lv_selCust.custNm = lv_vm.custListObj[0].custList[0].custNm
                  lv_vm.lv_selCust.chnlCustId = lv_vm.custListObj[0].custList[0].chnlCustId
                  let age = 0
                  let birthDate = lv_vm.custListObj[0].custList[0].dob
                  let contractDate = moment(new Date().toISOString()).format('YYYYMMDD')
                  let fullMonths = 0
                  if (parseInt(contractDate.substring(0, 4)) < 1988) {
                    fullMonths = DateUtil.getMonthPeriodBySingleCalc(birthDate, contractDate)
                  } else {
                    fullMonths = DateUtil.getPassedMonthCount(birthDate, contractDate)
                  } // end else if
                  let years = parseInt(fullMonths / 12)
                  let months = parseInt(fullMonths % 12)
                  if (months > 5) {
                    age = years + 1
                  } else {
                    age = years
                  } // end else if
                  lv_vm.lv_selCust.age = age + '세' // 보험나이
                  
                  lv_vm.fn_nofacCnslBtnClick() // 고객별 화상상담 목록 팝업 열기   
                } else {
                        console.log('### MSPSA207M [비대면화상상담] 자료 저장 end >>' )
                        // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
                        // lv_vm.getStore('confirm').dispatch('ADD', '비대면상담 자료 저장을 완료했습니다.')
                        lv_vm.fn_nofaeCnsl_tostMesg()                              // 비대면상담 자료 저장을 완료했습니다                      
                }
              }
            } else {
              //lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },           
 
    /*********************************************************
     * Function명: fn_OpenPopSmsCustList
     * 설명:  고객선택 수신 팝업오픈
     * Params: N/A
     * Return: N/A 
     *********************************************************/
    fn_OpenPopSmsCustList() {
      let lv_vm = this
      this.custListObj = []

     lv_vm.lv_Popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
     properties: {
       pChnlCustIds: [
         {targetType: 's',
          parentsViewId: 'MSPSA207M',
          custList: []
         }
       ]
     },
     listeners: {
        onPopupClose: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
      },
      onNextService: (pData) => {
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup152)
        lv_vm.custListObj = [{targetType: 's', parentsViewId: 'MSPSA207M', custList: pData.selectList}] 
        lv_vm.fn_OpenMSPCM128P()
      },
    }
  })          

  },
    /*********************************************************
    * Function명: fn_OpenMSPCM128P
    * 설명: 수신고객목록 팝업오픈 (문자발송)
    * Params: N/A
    * Return: N/A
    *********************************************************/
    fn_OpenMSPCM128P: _.debounce(function () {
      let lv_vm = this
      let guidList = []
        // if(this.clickedSingleItem === true){
        //   guidList.push(this.lv_SelectedSingleItem) 
        // }else{
        //   guidList = this.lv_SelectedItemList
        // }
      //  if(this.lv_SelectedItemList.length > 1){
      //   guidList = this.lv_SelectedItemList
      // }else{
      //   guidList.push(this.lv_SelectedItemList)   
      // }   
        guidList = this.lv_SelectedItemList
        
        console.log('lv_vm.custListObj >> ', lv_vm.custListObj)
        console.log('lv_vm.guidList >> ',  guidList)
        lv_vm.lv_Popup128 = lv_vm.$bottomModal.open(MSPCM128P,{
        properties: {
          pChnlCustIds: lv_vm.custListObj, // param셋팅
          pSelectedUntySlmtItemList: guidList , 
          pIsShowTSSSP128P: true
        },
        listeners: {
            // 팝업 닫히면서 실행되는 함수
           onPopupClose: (pData) => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },
             // 단순 팝업 종료
           onPopupCancel: () => {
             lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
           },         
      // 터치온 발송 프로세스 async
           onNextService: (pData) => {
              lv_vm.$bottomModal.close(lv_vm.lv_Popup128)
              lv_vm.custListObj = []
              lv_vm.custListObj = pData.rtnData
             // 터치온 팝업 오픈(MSPCM154P)
              lv_vm.fn_OpenMSPCM154P()
           }
        }
      })    
    }, 300),    

/*********************************************************
 * Function명: fn_OpenMSPCM154P
 * 설명: 터치On 문자발송 팝업 오픈
 * 
 *********************************************************/
 fn_OpenMSPCM154P: _.debounce(function () {

  let lv_vm = this

  lv_vm.lv_Popup154 = lv_vm.$bottomModal.open(MSPCM154P, {
    properties: {
      pPage: lv_vm.custListObj.parentsViewId,                   // 모화면 페이지명
      pSmsTargetCustList: lv_vm.custListObj.smsTargetCustList,  // 수신고객리스트
      pMsg: lv_vm.custListObj.msg,                              // 선택된 상용구
      pIsNotEditable: lv_vm.custListObj.isNotEditable           // 메시지 수정가능여부
    },
    listeners: {
      onPopupClose: (pData) => { 
        if (pData.str === 'confirm') {
          lv_vm.getStore('toast').dispatch('ADD', '발송되었습니다.')
        }
        // 모달 닫기
        lv_vm.$bottomModal.close(lv_vm.lv_Popup154)
      }
    }
  })
  } , 300),      


  /*********************************************************
   * Function명: fn_OpenPopEmailCustList
   * 설명:  고객선택 수신 팝업오픈
   * Params: N/A
   * Return: N/A 
   *********************************************************/
    fn_OpenPopEmailCustList() {

      if (this.lv_SelectedItemList.length > 5) {
            this.$addSnackbar('선택 가능 자료는 최대 5개 입니다.')
           return
      } 

      let lv_vm = this
      this.custListObj =  [
         {targetType: 'e',
          parentsViewId: 'MSPSA207M',
          custList: []
         }
       ]
      let selectPDFfile = []

      lv_vm.lv_popup152 = lv_vm.$bottomModal.open(MSPCM152P, {
      properties: {
      //   pChnlCustIds: [
      //    {targetType: 'e',
      //     parentsViewId: 'MSPSA207M',
      //     custList: []
      //    }
      //  ],
      pChnlCustIds: lv_vm.custListObj,
        pPDFfile : selectPDFfile
      },
      listeners: {
        onPopupClose: (pData) => {
          // 모달 닫기
          lv_vm.$bottomModal.close(lv_vm.lv_popup152)
        },
        onNextService: async (pData, selectPDFfile) => {
         // 중복제거
          let custList = []    // 문자수신 고객 list
          let cnt = 0
  
          if (pData.selectList.length > 0) {
            for (let i = 0; i < pData.selectList.length; i++) { 
              cnt = 0
              if (i === 0) {
                custList.push(pData.selectList[i])
              } else {
                for (let j = 0; j < custList.length; j++) {
                  if (pData.selectList[i].chnlCustId === custList[j].chnlCustId) {
                    cnt++
                  }
                }
                if (cnt === 0) {
                  custList.push(pData.selectList[i])
                }
              }
            }
          }

          lv_vm.custListObj = [{targetType: 'e', parentsViewId: 'MSPSA207M', custList: custList}]  
          selectPDFfile = lv_vm.lv_SelectedItemList
          let lv_pTitle = '삼성생명' 
          let lv_pText = '고객님 안녕하세요.\n' +
                          '삼성생명 ' + this.getStore('userInfo').getters.getUserInfo.userNm + '입니다.\n' +
                          '고객님께 도움이 될 만한 자료가 있어 보내드립니다.\n' +
                          '오늘도 즐거운 하루 되세요!'  
          let lv_pDataModify = true                
          if (lv_vm.custListObj[0].custList.length > 0) {
            // 모달 닫기
            await lv_vm.$bottomModal.close(lv_vm.lv_popup152)
            // 문자/이메일/DM/택배 클릭시 호출(이벤트 최종 목적지 배분)
            await lv_vm.$cmUtil.fn_OpenMSPCM191P(lv_vm, lv_vm.custListObj, selectPDFfile, lv_pTitle, lv_pText, lv_pDataModify)
          }
        },
      }
     })
    },
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked
     * 설명: 인쇄
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzConfirmBtnClicked() {
      if (this.lv_SelectedItemList.length === 0) {  
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
      } else  { 
              this.pIsSelectedMultiItem = false
              this.selectItems = [] // 초기화
              this.selectItems = this.lv_SelectedItemList
              this.lv_SelectedItemListTmp = [] // 초기화
              // this.selectItems.forEach(item => {
              //     // My 세일즈북의 특정 그룹에 포함된 자료 VO에 없는 untySlmtClsfNm01를 강제로 설정
              //     // My 세일즈북의 자료를 상세보기 할 때, '라이브러리'라는 이름의 대 분류에 포함된 자료일 경우는 툴팁 메뉴를 비활성화 시켜주기 위해
              //    item.untySlmtClsfNm01 = item.untySlmtClsfNm                 
              // })
              this.lv_SelectedItemListTmp = this.selectItems.slice(0)              
              if(this.lv_SelectedItemListTmp.length > 1) {
                 this.pIsSelectedMultiItem = true
               }
               this.fn_OzPrintCntntList()
      }
    },      
    /*********************************************************
     * Function명: fn_OzConfirmBtnClicked()
     * 설명: 선택 자료 목록 인쇄 처리
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_OzPrintCntntList() {
      console.log('@@@ MSPSA207M 인쇄  fn_OzPrintCntntList START...☞ ' + new Date().getTime())
      let lv_vm = this

      let tempUntySlmtItemList = []
      // 다중 선택일 경우 선택 자료 목록을 파라미터로 설정
      // if (lv_vm.pIsSelectedMultiItem) {
      //   tempUntySlmtItemList = lv_vm.lv_SelectedItemList
      // } else { // 단일 선택일 경우 선택 자료를 파라미터로 설정
      //   tempUntySlmtItemList.push(lv_vm.lv_SelectedItemList)
      // }
      tempUntySlmtItemList = lv_vm.lv_SelectedItemListTmp

      // 로컬 테스트용 코드
      if (process.env.NODE_ENV === 'local') {
        tempUntySlmtItemList.forEach(item => {
          item.pdfApndFileStoreNm = 'test_local.pdf'
          item.pdfApndFilePathNm = ''
        })
      }

      let pParams = {
        cnsltNo: this.getStore('userInfo').getters.getUserInfo.userId,
        pblNos: lv_vm.lv_SpinValue,                 // 기본 1 매 설정 
        sACntntBasInfoSVO: tempUntySlmtItemList,
        cnsltInfoIcluYN: this.lv_IsIncludeCnsltInfo  // 컨설턴트 개인정보 이미지 미 포함으로 설정됨.
      }

      const trnstId = 'txTSSSA11P9'
      // const trnstId = 'txTSSSA11P1'
      const auth = 'S'
      this.post(lv_vm, pParams, trnstId, auth)
        .then(function (response) {
          if ( lv_vm.$commonUtil.isSuccess(response) ) { // response.msgComm.msgCd 값을 통해 this.$commonUtil 메소드에서 성공 여부를 체크
            // 서비스 후처리
            if (response.body !== null && response.body !== '') { // 서비스 호출 결과(body)가 있을 경우
                  console.log('@@@ MSPSA210M   인쇄  fn_OzPrintCntntList this.post START ☞ ' + new Date().getTime())
              if (response.body.sACntntSVO.length === lv_vm.selectItems.length) {
              // 출력 건수가 선택된 아이템별 선택 매수와 동일할 경우
                  lv_vm.fn_printtostMesg()                              // 인쇄처리 완료 메세지  
                //lv_vm.$emit('evtCloseTSSSA011P', true)
              }
            } else { // 서비스 호출은 정상이지만 비지니스 에러가 존재할 경우 코드 및 메시지 출력
             // lv_vm.getStore('confirm').dispatch('SETCOLOR', 'purple')
              lv_vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
            }
          }
           console.log('@@@ MSPSA207M 인쇄  fn_OzPrintCntntList END ☞ ' + new Date().getTime())
        })
        .catch(function (error) {
          window.vue.error(error)
           console.log('@@@ MSPSA207M 인쇄  fn_OzPrintCntntList Error ☞ ' + new Date().getTime())
        })
    },            
   /*********************************************************
     * Function명: fn_OpenMSPSA210P
     * 설명:  스크랩팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA210P() {
            console.log("MSPSA207M-  fn_OpenMSPSA210M  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup210 = this.$bottomModal.open(MSPSA210P,{
        properties: {
          pPage: 'MSPSA207M',
          pSelectedUntySlmtItems: this.selectItems.slice() // param셋팅
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            popupCloseMSPSA210P: (pData) => {
                if(pData !== ''){              
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }else{
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup210)
                }
            }
         }
        })
      } 
    }, 
   /*********************************************************
     * Function명: fn_OpenMSPSA222M
     * 설명:  스크랩팝업오픈
     * Params: N/A
     * Return: N/A this.lv_SelectedUntySlmtItems
     *********************************************************/
    fn_OpenMSPSA222P() {
            console.log("MSPSA207M-  fn_OpenMSPSA222P  start ")

      if (this.lv_SelectedItemList.length === 0) { 
         //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
         this.getStore('confirm').dispatch('ADD', '자료를 선택하여 주십시요.')
         //this.fn_BottomSheet_open()
      } else {            
        this.selectItems = [] // 초기화
        this.selectItems = this.lv_SelectedItemList.slice()
         

        let lv_vm = this
        let itemList = []
        this.lv_Popup222 = this.$bottomModal.open(MSPSA222P,{
        properties: {
          pPage: 'MSPSA207M',
          pTargetType:  lv_vm.lv_TargetType,
          pSelectedCustList: [],
          pSearchCustNm: ''
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
            onPopupConfirm222P: (pData) => {
                if(pData !== ''){      
                  console.log("MSPSA207M-  onPopupConfirm222P  return1 " , pData)
                   lv_vm.lv_SelectedCustList = pData
                   lv_vm.fn_SaveToCouncel()
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)                           
                }else{
                  console.log("MSPSA207M-  onPopupConfirm222P  return2 ")
                   lv_vm.lv_SelectedCustList = []
                   lv_vm.$bottomModal.close(lv_vm.lv_Popup222)
                }
            },
            onPopupClose222P: () => {
                   this.$bottomModal.close(this.lv_Popup222);
            }
         }
        })
      } 

    }, 
        

    /******************************************************************************
     * Function명 : fn_nofacCnslBtnClick
     * 설명       : 화상상담자료조회popup호출 - MSPSA063P
     ******************************************************************************/
      fn_nofacCnslBtnClick() {
                // 최대 30명까지 선택하도록 제한한다.
        const LIMITED_SMS_SELECTED = 30
        var lv_vm = this

        if (this.lv_SelectedCustList.length > LIMITED_SMS_SELECTED) {
            window.fdpbridge.exec('ToastPlugin', {message: '최대 30명까지만 문자발송 가능.\n총선택 인원을 확인해주세요.'}, () => {}, () => {})
        } else if (this.lv_SelectedCustList.length === 0) {
            window.fdpbridge.exec('ToastPlugin', {message: '선택 인원을 확인해주세요.'}, () => {}, () => {})
        } else {
          // 화상상담자료조회popup호출
            this.isMSPSA063P = this.$bottomModal.open(MSPSA063P, {
                  properties: {
                    pNofacCnslCust: lv_vm.custListObj[0].custList[0]    //this.lv_SelectedCustList[0]    
                  },
                  listeners: {
                    onPopupConfirm: () => {
                      this.$bottomModal.close(this.isMSPSA063P);
                    },
                    onPopupClose: () => {
                      this.$bottomModal.close(this.isMSPSA063P);
                    }
                  }
                },
                {
                  properties: {
                    noHeader: true
                  }
                })
        }        
      },  
      /*********************************************************
      * Function명: fn_print_BottomSheet_XXXX
      * 설명: 인쇄 처리용 버텀 시트 제어
      * Params: N/A
      * Return: N/A
      *********************************************************/
      fn_nofaeCnsl_tostMesg(){
          //this.$refs.stackableSnackbar.show({text:'비대면상담 자료를 저장하였습니다.'});
          this.getStore('toast').dispatch('ADD', '비대면상담 자료를 저장하였습니다.')
          this.fn_print_BottomSheet_close()
      },      
      fn_printtostMesg(){
          //this.$refs.stackableSnackbar.show({text:'인쇄되었습니다.'});
          this.getStore('toast').dispatch('ADD', '인쇄되었습니다.')
          this.fn_print_BottomSheet_close()
      },      
      fn_print_BottomSheet_open() { 
        if (this.lv_SelectedItemList.length < 1) { 
           //this.getStore('confirm').dispatch('SETCOLOR', 'purple')
           this.getStore('confirm').dispatch('ADD', '인쇄할 자료를 선택하여 주십시요.')
        } else {
            this.close()
            this.$refs.print_bottomSheet.open();
        }    
      },
      fn_print_BottomSheet_close() { 
        this.$refs.print_bottomSheet.close();
        this.open()
      },
      fn_video_bottomsheet_open() {
        this.$refs.video_bottomsheet.open();
        this.close()
      },
      fn_video_bottomsheet_close() {
        this.$refs.video_bottomsheet.close();
        //this.open()
      },

      fn_HistoryBack () {
        // 체크박스가 있는 경우
        console.log("fn_HistoryBack- 1  lv_SelectedItemList >>" , this.lv_SelectedItemList  )
              console.log("fn_HistoryBack- 2  lv_SelectedItemList >>" , this.lv_SelectedSingleItem  )
        if ((this.lv_SelectedItemList  !== null && this.lv_SelectedItemList.length > 0  )  ||
            (this.lv_SelectedSingleItem !== null  && this.lv_SelectedSingleItem.length > 0  ) ){          
                let alertMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'

                let alertObj = this.$bottomModal.open(MSPBottomAlert, {
                  properties: {
                      type: 'B',
                      content: alertMsg,
                      title_pos_btn: "예",
                      title_neg_btn: "아니오"
                  },
                  listeners: {
                    onPopupConfirm: () => {
                     this.$bottomModal.close(alertObj)                
                      // 특정화면 이동 혹은 이전 화면 이동
                      this.$router.go(-2)
                   },
                    onPopupClose: () => {
                     this.$bottomModal.close(alertObj)
                    }
                 }
               })
        } else {
          console.log("1" )
            this.$router.go(-2) // 이전화면으로 이동
         }
      }
    },
  };
</script>
<style scoped>
</style>